export const resolveMQ = (theme, breakpoints, mq) => {
  let newValue = {};
  for (let key in theme) {
    if (breakpoints[key]) {
      if (mq === key) {
        newValue = {...newValue, ...theme[key]};
      }
    } else {
      let value = theme[key];
      if (!Array.isArray(value) && typeof value === 'object') {
        value = resolveMQ(value, breakpoints, mq);
      }
      newValue[key] = value;
    }
  }
  return newValue;
};

export function getClosestBreakpoint(breakpoints, point) {
  const dimValues = Object.values(breakpoints);
  let index = -1;
  let breakpointsObj = {};
  for (let i = 0; i < dimValues.length; i++) {
    breakpointsObj[dimValues[i]] = i;
  }
  const breakpointsKeys = Object.keys(breakpointsObj);
  for (let i = 0; i < breakpointsKeys.length; i++) {
    if (parseInt(breakpointsKeys[i]) === point) {
      index = breakpointsObj[breakpointsKeys[i]];
      break;
    } else if (parseInt(breakpointsKeys[i]) > point && i !== 0) {
      index = breakpointsObj[breakpointsKeys[i - 1]];
      break;
    }
    // If windowWidth is greater than last available breakpoint clamp it to last index
    else if (
      parseInt(breakpointsKeys[i]) < point &&
      i === dimValues.length - 1
    ) {
      index = breakpointsObj[breakpointsKeys[i]];
      break;
    }
  }
  return index;
}
