class Keyboard {}

Keyboard.addListener = () => {};

Keyboard.removeListener = () => {};

Keyboard.dismiss = () => {
  return {};
};
export default Keyboard;
