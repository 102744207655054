import React, {useState, useEffect} from 'react';
import moment from 'moment';
import ToggleButton from '../../components/ToggleButton/ToggleButton';
import CalendarPicker from '../../components/CalendarPicker/CalendarPicker';
import {useIntl} from 'react-intl';
import InputLabel from '../../components/InputLabel/InputLabel.js';

function SettingsForm({
  onCancel,
  formData,
  onChange,
  setFormData,
  onDateChange,
  editMode,
  id,
  errors,
}) {
  const intl = useIntl();
  const {
    recieve_new_contact_announcements,
    recieve_task_reminder,
    recieve_weekly_progress,
    pilot_program,
  } = formData;

  const [myDate, setMyDate] = useState(null);
  const [myEndDate, setMyEndDate] = useState(null);

  const handleToggleChange = (settingName, newValue) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [settingName]: newValue,
    }));
  };

  const handleDateChangeStart = e => {
    setMyDate(e.target.value);
    onDateChange(e.target.value, 'start');
  };
  const handleDateChangeEnd = e => {
    const endDate = e.target.value;
    setMyEndDate(endDate);
    onDateChange(endDate, 'end');
  };

  return (
    <div className="container setting_form add-school-wrapper">
      <form>
        <div className="mb-3 col-md-12 license-date-wrapper">
          <InputLabel
            type="date"
            name="license_start_date"
            label={intl.formatMessage({id: 'label.license_start_date'})}
            required={false}
            placeholder={intl.formatMessage({
              id: 'label.enter_contact_form_name',
            })}
            id="license_start_date"
            value={formData.license_start_date?.substring(0, 10)}
            onChange={handleDateChangeStart}
            htmlFor="contactFirstName"
            className="license-date"
            subtitle={intl.formatMessage({id: 'label.license_start_subtitle'})}
            disabled={!editMode && id}
          />
        </div>

        <div className="mb-3 col-md-12 license-date-wrapper">
          <InputLabel
            type="date"
            name="license_expiry_date"
            label={intl.formatMessage({id: 'label.license_expiry_date'})}
            required={false}
            placeholder={intl.formatMessage({
              id: 'label.enter_contact_form_name',
            })}
            id="license_expiry_date"
            value={formData.license_expiry_date?.substring(0, 10)}
            onChange={handleDateChangeEnd}
            htmlFor="contactFirstName"
            className="license-date"
            subtitle={intl.formatMessage({id: 'label.license_start_subtitle'})}
            disabled={!editMode && id}
          />
        </div>

        <div className="row">
          <h2>{intl.formatMessage({id: 'label.pilot_program'})}</h2>

          <div className="mb-3 col-md-6 toggle-field">
            <div className="mb-3 col-md-12 license-date-wrapper gap-3">
              <div>
                <div className="d-flex align-items-center gap-3 mb-2">
                  <label
                    htmlFor="pilot-program"
                    className="form-label cus-form-label m-0">
                    {intl.formatMessage({id: 'label.pilot_program'})}{' '}
                  </label>
                </div>
                <p>
                  {intl.formatMessage({id: 'label.pilot_program_description'})}
                </p>
              </div>
              <ToggleButton
                initialState={pilot_program}
                onToggle={newValue =>
                  handleToggleChange('pilot_program', newValue)
                }
                disabled={!editMode && id}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <h2>{intl.formatMessage({id: 'label.notification_settings'})}</h2>

          <div className="mb-3 col-md-6 toggle-field">
            <div>
              <label htmlFor="country" className="form-label cus-form-label">
                {intl.formatMessage({id: 'label.new_content_announcements'})}{' '}
              </label>
              <p>{intl.formatMessage({id: 'label.new_content_subtitle'})}</p>
            </div>
            <ToggleButton
              initialState={recieve_new_contact_announcements}
              onToggle={newValue =>
                handleToggleChange(
                  'recieve_new_contact_announcements',
                  newValue,
                )
              }
              disabled={!editMode && id}
            />
          </div>
          <div className="mb-3 col-md-6 toggle-field">
            <div>
              <label htmlFor="state" className="form-label cus-form-label">
                {intl.formatMessage({id: 'label.task_reminder'})}{' '}
              </label>
              <p>{intl.formatMessage({id: 'label.task_reminder_subtitle'})}</p>
            </div>
            <ToggleButton
              initialState={recieve_task_reminder}
              onToggle={newValue =>
                handleToggleChange('recieve_task_reminder', newValue)
              }
              disabled={!editMode && id}
            />
          </div>

          <div className="mb-6 col-md-6 toggle-field">
            <div>
              <label
                htmlFor="fullAddress"
                className="form-label cus-form-label">
                {intl.formatMessage({id: 'label.weekly_progress'})}{' '}
              </label>
              <p>
                {intl.formatMessage({id: 'label.weekly_progress_subtitle'})}
              </p>
            </div>
            <ToggleButton
              initialState={recieve_weekly_progress}
              onToggle={newValue =>
                handleToggleChange('recieve_weekly_progress', newValue)
              }
              disabled={!editMode && id}
            />
          </div>
        </div>

        <div className="row">
          <h2>{intl.formatMessage({id: 'label.limits_settings'})}</h2>

          <p>{intl.formatMessage({id: 'label.limits_settings_subtitle'})}</p>

          <div className="mb-3 col-md-6">
            <InputLabel
              name="student_limit"
              label={intl.formatMessage({id: 'label.students_limit'})}
              placeholder={intl.formatMessage({
                id: 'label.enter_students_limit',
              })}
              required
              type="number"
              value={formData.student_limit}
              onChange={onChange}
              htmlFor="student_limit"
              disabled={!editMode && id}
            />
            {errors.student_limit && (
              <div className="error-message">{errors.student_limit}</div>
            )}
          </div>

          <div className="mb-3 col-md-6">
            <InputLabel
              name="school_admin_limit"
              label={intl.formatMessage({id: 'label.school_admins_limit'})}
              placeholder={intl.formatMessage({
                id: 'label.enter_school_admins_limit',
              })}
              required
              type="number"
              value={formData.school_admin_limit}
              onChange={onChange}
              htmlFor="school_admin_limit"
              disabled={!editMode && id}
            />
            {errors.school_admin_limit && (
              <div className="error-message">{errors.school_admin_limit}</div>
            )}
          </div>

          <div className="mb-3 col-md-6">
            <InputLabel
              name="teacher_limit"
              label={intl.formatMessage({id: 'label.teachers_limit'})}
              placeholder={intl.formatMessage({
                id: 'label.enter_teachers_limit',
              })}
              required
              type="number"
              value={formData.teacher_limit}
              onChange={onChange}
              htmlFor="teacher_limit"
              disabled={!editMode && id}
            />
            {errors.teacher_limit && (
              <div className="error-message">{errors.teacher_limit}</div>
            )}
          </div>

          <div className="mb-3 col-md-6">
            <InputLabel
              name="counselor_limit"
              label={intl.formatMessage({id: 'label.counsellors_limit'})}
              placeholder={intl.formatMessage({
                id: 'label.enter_counsellors_limit',
              })}
              required
              type="number"
              value={formData.counselor_limit}
              onChange={onChange}
              htmlFor="counselor_limit"
              disabled={!editMode && id}
            />
            {errors.counselor_limit && (
              <div className="error-message">{errors.counselor_limit}</div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default SettingsForm;
