import React, {useContext, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router';
import {convertFromRaw} from 'draft-js';
import Divider from '@mui/material/Divider';
import {stateToHTML} from 'draft-js-export-html';
import {GlobalLoaderContext} from '../../../globalContext/globalLoader/globalLoaderProvider';
import {getLessonStyle} from '../../../store/actions/islandActions';
import {
  getAttachmentFileName,
  getAttachmentFileNameWithoutFileExtension,
  getDocumentIcon,
  getPreviewDocFileName,
  getPreviewFormData,
} from '../../../utilityFunction/helper';
import ContentWrapper from './ContentWrapper';
import SelectDropdown from '../../../components/SelectDropdown/SelectDropdown.js';
import BackdropGlobalLoader from '../../../components/BackdropGlobalLoader/BackdropGlobalLoader';
import {getLessonContentData} from '../../../store/actions/lessonActions';
import CustomModalStudent from '../../../components/CustomModalStudent/CustomModalStudent';
import StudentDocViewer from '../../../components/StudentDocViewer/StudentDocViewer';
import StudentResourcesTabs from '../StudentResourcesTabs/StudentResourcesTabs';
import {
  addBookmark,
  deleteBookmark,
} from '../../../store/actions/bookmarkActions';
import {commonStyle} from '../../../Style/commonStyle';
import {
  downloadFileAttachments,
  downloadAttachments,
} from '../../../store/actions/fileActions';
import {
  LESSON_CONTENT_OBJECT_TYPE,
  VOICE_OPTIONS,
} from '../../../utilityFunction/constants';
import UserRoles from '../../../constants/userRoles';
import useUserRole from '../../../hooks/useUserRole';
import StaffResourcesTabs from '../../StaffResourcesTabs/StaffResourcesTabs';
import {setLoading} from '../../../globalContext/globalLoader/globalLoaderAction';
import {showToast} from '../../../components/Toast/Toast.js';
import './StudentViewLessonContent.scss';

export default function StudentViewLessonContent({
  currentLesson,
  isPreviewAvailable,
  formValues,
  setMyLessonInformation,
  isSpeaking,
  setIsSpeaking,
  nextModuleClicked,
  previousModuleClicked,
  setNextModuleClicked,
  setPreviousModuleClicked,
}) {
  const intl = useIntl();
  const userRole = useUserRole();
  const dispatch = useDispatch();
  const authData = useSelector(state => state?.auth);
  const location = useLocation();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const {lessonStyleData} = useSelector(state => state?.island);
  const {lessonContentData} = useSelector(state => state?.lesson);
  const [sortedLessonContents, setSortedLessonContent] = useState([]);
  const [openMediaModal, setOpenMediaModal] = useState(false);
  const [media, setMedia] = useState(null);
  const [mediaPath, setMediaPath] = useState('');
  const studentId = authData?.data?.data?.data?.user?.student_data?.id;
  const {lessonInfo, isStaffOrSchoolAdmin, chosenQuestion} =
    location?.state || {};
  const [textToSpeech, setTextToSpeech] = useState('');
  const [audioSrc, setAudioSrc] = useState('');
  const [resultId, setResultId] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const audioRef = useRef(null);
  const [selectedVoice, setSelectedVoice] = useState(VOICE_OPTIONS[0].id);
  const [localIsSpeaking, setLocalIsSpeaking] = useState(false);

  // Load environment variables
  const REACT_APP_SPEECHGEN_API_TOKEN =
    process.env.REACT_APP_SPEECHGEN_API_TOKEN;
  const REACT_APP_SPEECHGEN_EMAIL = process.env.REACT_APP_SPEECHGEN_EMAIL;

  const getLessonStyleObj = type => {
    const styleObject = lessonStyleData.find(style => style?.type === type);
    return styleObject;
  };

  useEffect(() => {
    if (isPreviewAvailable && formValues) {
      const myFormModifiedData = getPreviewFormData(formValues);
      setSortedLessonContent(myFormModifiedData);
    }
  }, [isPreviewAvailable, formValues]);

  useEffect(() => {
    dispatch(getLessonStyle({loaderDispatch}));
  }, []);

  useEffect(() => {
    if (currentLesson && currentLesson?.id && !isPreviewAvailable) {
      fetchLessonContent();

      const text = extractTextAndDescriptions(currentLesson);
      setTextToSpeech(text);
    }
  }, [currentLesson]);

  useEffect(() => {
    if (!isPreviewAvailable && lessonContentData?.lesson_content) {
      const sortedData = [...lessonContentData.lesson_content].sort(
        (a, b) => a?.content_priority - b?.content_priority,
      );
      setSortedLessonContent(sortedData);
    }
  }, [lessonContentData, isPreviewAvailable]);

  useEffect(() => {
    if (isStaffOrSchoolAdmin && lessonInfo) {
      dispatch(
        getLessonContentData({
          loaderDispatch,
          lessonId: lessonInfo?.id,
          status: 'published',
        }),
      );
    }
  }, [isStaffOrSchoolAdmin, lessonInfo]);

  // @TODO - Remove hard coded path after the database value is changed from docx to pdf.
  useEffect(() => {
    if (media) {
      setMediaPath(
        media?.name === '1715819698_Entrepreneurship.docx'
          ? 'https://gradrockapp.ydodev.com/backend/storages/media/T4-F Entrepreneurship.pdf'
          : media?.path,
      );
    }
  }, [media]);

  const fetchLessonContent = () => {
    dispatch(
      getLessonContentData({
        loaderDispatch,
        lessonId: currentLesson?.id,
        status: currentLesson?.status,
      }),
    )
      .unwrap()
      .then(response => {
        setMyLessonInformation(() => ({
          is_completed: response?.is_completed,
          minimum_read_time: response?.minimum_read_time,
          id: response?.id,
          name: response?.name,
        }));
      });
  };

  const viewMediaHandler = media => {
    setMedia(media);
    setOpenMediaModal(true);
  };

  const onBookMarkHandler = (content, isLesson) => {
    if (content?.is_bookmark || content?.is_lesson_bookmarked) {
      // Case : When lesson or content is already bookmarked , and we have to UnBookmark it
      const id = content?.bookmark_id;
      dispatch(deleteBookmark({id, intl, isLesson})).then(() => {
        dispatch(
          getLessonContentData({
            loaderDispatch,
            lessonId: currentLesson?.id,
            status: currentLesson?.status,
          }),
        );
      });
      return;
    } else {
      if (!studentId) {
        console.error('No Student Found');
        return;
      }
      const payload = {
        student_id: studentId,
        lesson_id: currentLesson?.id,
        ...(!isLesson && {content_id: content?.id}),
      };
      dispatch(addBookmark({payload, intl, isLesson})).then(() => {
        dispatch(
          getLessonContentData({
            loaderDispatch,
            lessonId: currentLesson?.id,
            status: currentLesson?.status,
          }),
        );
      });
    }
  };

  const onAttachmentsDownload = idMedia => {
    dispatch(downloadFileAttachments({idMedia, intl, loaderDispatch}));
  };

  const handleAttachmentsDownload = media => {
    dispatch(downloadAttachments({media, intl, loaderDispatch}));
  };

  function renderLessonContent(lessonContent, intl) {
    switch (lessonContent.type) {
      case LESSON_CONTENT_OBJECT_TYPE.ALERT:
      case LESSON_CONTENT_OBJECT_TYPE.NOTE:
      case LESSON_CONTENT_OBJECT_TYPE.BEST_ADVICE:
      case LESSON_CONTENT_OBJECT_TYPE.REMEMBER:
        const lessonStyleObject = getLessonStyleObj(lessonContent?.type);
        const backgroundColor = lessonStyleObject?.background_color;
        const mediaPath = lessonStyleObject?.media?.path;
        return (
          <RenderContentType
            lessonContent={lessonContent}
            backgroundColor={backgroundColor}
            mediaPath={mediaPath}
            contentType={lessonStyleObject?.label}
          />
        );
      case LESSON_CONTENT_OBJECT_TYPE.TEXT:
        return <RenderTextType lessonContent={lessonContent} />;
      case LESSON_CONTENT_OBJECT_TYPE.ARTICLE:
        return (
          <RenderArticleType
            lessonContent={lessonContent}
            intl={intl}
            onArticleBookmark={() => onBookMarkHandler(lessonContent, false)}
            showBookMark={isBookmarkVisible}
          />
        );
      case LESSON_CONTENT_OBJECT_TYPE.DOCUMENT:
        return (
          <RenderDocumentType
            lessonContent={lessonContent}
            viewMediaHandler={viewMediaHandler}
            onDocBookMark={() => onBookMarkHandler(lessonContent, false)}
            isPreviewAvailable={isPreviewAvailable}
            showBookMark={isBookmarkVisible}
          />
        );
      case 'image':
      case 'video':
        return <RenderImageVideoType lessonContent={lessonContent} />;
      default:
        return null;
    }
  }

  function extractTextAndDescriptions(data) {
    const textBlocks = [];

    // Create a copy of the lesson_content array and sort the copy by id in ascending order
    const sortedLessonContent = [...data.lesson_content].sort(
      (a, b) => a.id - b.id,
    );

    sortedLessonContent.forEach(item => {
      if (item.type === 'text') {
        const descriptionObj = JSON.parse(item.description);
        descriptionObj.blocks.forEach(block => {
          if (block.text) {
            textBlocks.push(block.text.replace(/\n/g, ' '));
          }
        });
      } else {
        if (item.description) {
          textBlocks.push(
            `${item.type.toUpperCase()}: ${item.description.replace(
              /\n/g,
              ' ',
            )}`,
          );
        }
      }
    });

    return textBlocks.join(' ');
  }

  // Function to handle text-to-speech with speechgen.io API
  const onVoiceSelect = e => {
    setSelectedVoice(e.target.value);
    localStorage.setItem('selectedVoice', selectedVoice);
  };

  const handleSpeak = async () => {
    if (!selectedVoice) {
      showToast('Please select a voice.', 'error');
      return;
    }

    let finalText = '';

    localStorage.setItem('selectedVoice', selectedVoice);

    if (userRole !== 'student') {
      const myFormModifiedData = getPreviewFormData(formValues);
      const textResult = myFormModifiedData.map(item => {
        if (item.type === 'document') {
          return ''; // Return an empty string to exclude 'document' type
        }
        let text = '';
        try {
          const descriptionObj = JSON.parse(item.description);
          text = descriptionObj.blocks.map(block => block.text).join(' ');
        } catch (e) {
          text = item.description;
        }

        // Handle all item types except 'text'
        if (item.type !== 'text') {
          text = `${item.type.toUpperCase()}: ${text}`;
        }

        return text;
      });
      const text = textResult.join(' ').replace(/\s+/g, ' ').trim();
      finalText = text;
      setTextToSpeech(text);
    }

    const data = {
      token: REACT_APP_SPEECHGEN_API_TOKEN,
      email: REACT_APP_SPEECHGEN_EMAIL,
      voice: selectedVoice,
      text: textToSpeech || finalText,
      format: 'mp3',
      speed: 1,
      pitch: 0,
      emotion: 'good',
    };

    if (audioSrc && resultId !== null) {
      if (audioRef.current) {
        audioRef.current.play();
        setIsSpeaking ? setIsSpeaking(true) : setLocalIsSpeaking(true);
      }
      return;
    }

    try {
      loaderDispatch(setLoading(true));

      const response = await fetch(
        'https://speechgen.io/index.php?r=api/longtext',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams(data),
        },
      );

      const result = await response.json();

      setResponseMessage(JSON.stringify(result));

      if (result.status === 1) {
        setResultId(result.id);
        setAudioSrc(result.file);
      } else if (result.status === 0) {
        setResultId(result.id);
      } else {
        console.error(result.error);
      }
    } catch (error) {
      console.error('Error sending text to API:', error);
      loaderDispatch(setLoading(false));
      showToast('There was an error in generating the speech.', 'error');
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (resultId) {
        const checkStatus = async () => {
          const data = {
            token: REACT_APP_SPEECHGEN_API_TOKEN,
            email: REACT_APP_SPEECHGEN_EMAIL,
            id: resultId,
          };

          try {
            loaderDispatch(setLoading(true));

            const response = await fetch(
              'https://speechgen.io/index.php?r=api/result',
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(data),
              },
            );

            const result = await response.json();
            setResponseMessage(JSON.stringify(result));

            if (result.status === '1') {
              setAudioSrc(result.file);
              setResultId(null); // Clear resultId once audio is ready
              loaderDispatch(setLoading(false));
            } else if (result.status === '0') {
              console.log('Voice generation in process...');
            } else {
              console.error(result.error);
              loaderDispatch(setLoading(false));
            }
          } catch (error) {
            console.error('Error checking dubbing status:', error);
            showToast('Error checking dubbing status', 'error');
            loaderDispatch(setLoading(false));
          }
        };

        checkStatus();
      }
    }, 100);

    return () => clearInterval(interval);
  }, [resultId]);

  useEffect(() => {
    if (
      (!isSpeaking || !localIsSpeaking) &&
      (nextModuleClicked || previousModuleClicked)
    ) {
      handleStopSpeaking();
      setNextModuleClicked(false);
      setPreviousModuleClicked(false);
      setIsSpeaking ? setIsSpeaking(false) : setLocalIsSpeaking(false);
      setResultId(null);
    }
  }, [isSpeaking, nextModuleClicked, previousModuleClicked]);

  useEffect(() => {
    if (audioRef.current) {
      const audioElement = audioRef.current;
      const handlePlay = () =>
        setIsSpeaking ? setIsSpeaking(true) : setLocalIsSpeaking(true);
      const handlePause = () =>
        setIsSpeaking ? setIsSpeaking(false) : setLocalIsSpeaking(false);
      const handleEnded = () =>
        setIsSpeaking ? setIsSpeaking(false) : setLocalIsSpeaking(false);

      audioElement.addEventListener('play', handlePlay);
      audioElement.addEventListener('pause', handlePause);
      audioElement.addEventListener('ended', handleEnded);

      return () => {
        audioElement.removeEventListener('play', handlePlay);
        audioElement.removeEventListener('pause', handlePause);
        audioElement.removeEventListener('ended', handleEnded);
      };
    }
  }, [audioSrc]);

  const handleStopSpeaking = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reset audio to the beginning
      setIsSpeaking ? setIsSpeaking(false) : setLocalIsSpeaking(false);
    }
  };

  const showBookMarkHandler = () => {
    const bookMarkRoles = [UserRoles.STUDENT];
    if (bookMarkRoles.includes(userRole)) return true;
    else return false;
  };

  const checkIsStudentRole = () => {
    if (userRole === UserRoles.STUDENT) return true;
    else return false;
  };

  const isBookmarkVisible = showBookMarkHandler();
  const isStudentRole = checkIsStudentRole();

  useEffect(() => {
    const storedVoice = localStorage.getItem('selectedVoice');

    if (storedVoice) {
      setSelectedVoice(storedVoice);
    }
  }, []);

  return (
    <>
      {isStaffOrSchoolAdmin ? (
        <div className="teacherCounsellor-viewContent-wrapper">
          <div className="contents-label">
            {intl.formatMessage({id: 'label.module-contents'})}
          </div>
          <div className="view-lesson-contents-wrapper">
            <div className="view-lessons-contents">
              {lessonStyleData?.length > 0 &&
              sortedLessonContents?.length > 0 ? (
                sortedLessonContents?.map((lessonContent, index) => (
                  <React.Fragment key={`${index}@${lessonContent?.id}`}>
                    {renderLessonContent(lessonContent, intl)}
                  </React.Fragment>
                ))
              ) : (
                <BackdropGlobalLoader open={true} />
              )}
            </div>
          </div>
          <StaffResourcesTabs
            lesson={lessonInfo}
            chosenQuestion={chosenQuestion}
          />
        </div>
      ) : (
        <>
          <div className="view-lesson-contents-wrapper">
            <div className="view-lessons-contents">
              {lessonStyleData?.length > 0 &&
              sortedLessonContents?.length > 0 ? (
                sortedLessonContents?.map((lessonContent, index) => (
                  <React.Fragment key={`${index}@${lessonContent?.id}`}>
                    {renderLessonContent(lessonContent, intl)}
                  </React.Fragment>
                ))
              ) : (
                <BackdropGlobalLoader open={true} />
              )}
            </div>

            {isBookmarkVisible && (
              <>
                <div className="d-flex flex-column gap-4">
                  <img
                    src={
                      lessonContentData?.is_lesson_bookmarked
                        ? '/images/bookmarkedIcon.svg'
                        : '/images/bookmark.svg'
                    }
                    alt="Lesson Bookmark"
                    className="lesson-bookmark-icon"
                    onClick={() => onBookMarkHandler(lessonContentData, true)}
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}

      {openMediaModal && (
        <CustomModalStudent
          open={openMediaModal}
          modalWidth={'90%'}
          showHeader
          dialogHeader={
            <StudentDocViewerHeader
              onBackDocs={() => setOpenMediaModal(false)}
              media={media}
              onAttachmentsDownload={handleAttachmentsDownload}
            />
          }>
          <StudentDocViewer mediaPath={mediaPath} />
        </CustomModalStudent>
      )}

      {(userRole === 'student' || userRole === 'super_admin') && (
        <div className="mt-4">
          <div className="mb-2">
            <label>Select a Voice:</label>
          </div>

          <div className="d-flex gap-2 align-items-center">
            <div>
              {isSpeaking || localIsSpeaking ? (
                <button
                  onClick={handleStopSpeaking}
                  className="text-to-speech-button">
                  <div>
                    <span>
                      <img
                        src="/images/stop-text-to-speech.png"
                        alt="Stop Text to Speech"
                      />
                    </span>
                  </div>
                </button>
              ) : (
                <button onClick={handleSpeak} className="text-to-speech-button">
                  <div>
                    <span>
                      <img src="/images/play-icon.png" alt="Text to Speech" />
                    </span>
                  </div>
                </button>
              )}
            </div>

            <div className="voice-select">
              <SelectDropdown
                value={selectedVoice}
                options={VOICE_OPTIONS}
                onChange={onVoiceSelect}
              />
            </div>
          </div>
        </div>
      )}

      {isStudentRole && <StudentResourcesTabs currentLesson={currentLesson} />}

      {audioSrc && <audio ref={audioRef} src={audioSrc} autoPlay />}
    </>
  );
}

const RenderContentType = ({
  lessonContent,
  backgroundColor,
  mediaPath,
  contentType,
}) => {
  const contentTypeStyle = {
    ...commonStyle.typeContentStyle,
    backgroundColor: backgroundColor,
  };

  return (
    <div style={contentTypeStyle}>
      <img
        src={mediaPath}
        className="content-type-icon"
        alt="Content type icon"
      />

      <div>
        <span className="content-type-text">{`${contentType}: `}</span>
        <span className="content-type-desc">{lessonContent?.description}</span>
      </div>
    </div>
  );
};

const RenderDocumentType = ({
  lessonContent,
  viewMediaHandler,
  onDocBookMark,
  isPreviewAvailable,
  showBookMark,
}) => {
  return (
    <ContentWrapper
      outerClassName="outer-doc-container"
      innerClassName="inner-doc-container">
      <div
        className="left-group"
        onClick={() => viewMediaHandler(lessonContent?.media)}>
        <img
          src={getDocumentIcon(lessonContent?.media?.name)}
          alt="File icon"
          className="file-icon"
        />
        <span className="document-file-name">
          {isPreviewAvailable
            ? getPreviewDocFileName(lessonContent?.media?.path)
            : getAttachmentFileName(lessonContent?.media?.name)}
        </span>
      </div>
      {showBookMark && (
        <img
          src={
            lessonContent?.is_bookmark
              ? '/images/bookmarkedIcon.svg'
              : '/images/bookmark.svg'
          }
          alt="Action icon"
          className="action-icon"
          onClick={onDocBookMark}
        />
      )}
    </ContentWrapper>
  );
};

const RenderArticleType = ({
  lessonContent,
  intl,
  onArticleBookmark,
  showBookMark,
}) => {
  return (
    <div className="article-type-wrapper">
      <div className="article-header-text">
        {intl.formatMessage({
          id: 'label.readArticleText',
        })}
      </div>
      <ContentWrapper
        outerClassName="outer-doc-container outer-article-container"
        innerClassName="inner-doc-container">
        <div className="student-article-content">
          <img src={lessonContent?.media?.path} className="article-img" />

          <div className="article-content-details">
            <div className="render-article-title">
              <a
                href={lessonContent?.article_link}
                target="_blank"
                rel="noopener noreferrer">
                {lessonContent?.article_title}
              </a>
            </div>
            {showBookMark && (
              <img
                src={
                  lessonContent?.is_bookmark
                    ? '/images/bookmarkedIcon.svg'
                    : '/images/bookmark.svg'
                }
                alt="Action icon"
                className="action-icon"
                onClick={onArticleBookmark}
              />
            )}
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
};

const RenderTextType = ({lessonContent}) => {
  const editorData = JSON.parse(lessonContent?.description);
  const convertFromRawValue = convertFromRaw(editorData);
  let html = stateToHTML(convertFromRawValue);

  return (
    <div className="text-line-wrapper">
      <Divider
        orientation="vertical"
        flexItem
        sx={commonStyle.textEditorDivider}
      />
      <div className="text-and-line-container">
        <div dangerouslySetInnerHTML={{__html: html}} />
      </div>
    </div>
  );
};

const RenderImageVideoType = ({lessonContent}) => {
  const videoPath = lessonContent?.media?.path;
  const videoType = videoPath?.endsWith('.webm') ? 'video/webm' : 'video/mp4';

  return (
    <>
      {lessonContent?.type === 'image' ? (
        <img
          src={lessonContent?.media?.path}
          alt="Content image"
          className="content-img"
        />
      ) : (
        <div>
          <video controls className="content-video">
            <source src={videoPath} type={videoType} />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
      )}
    </>
  );
};

const StudentDocViewerHeader = ({media, onBackDocs, onAttachmentsDownload}) => {
  return (
    <div className="doc-view-modal-header">
      <img
        src="/images/modalBackIcon.svg"
        alt="Back icon"
        onClick={onBackDocs}
        className="docs-view-icon"
      />

      <div className="doc-viewer-file-name">
        {getAttachmentFileNameWithoutFileExtension(media?.name)}
      </div>

      <img
        src="/images/downloadIcon.svg"
        alt="Download icon"
        className="docs-view-icon"
        onClick={() => onAttachmentsDownload(media)}
      />
    </div>
  );
};
