import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router';
import {useIntl} from 'react-intl';
import {PiDotsThreeVertical} from 'react-icons/pi';
import {Typography} from '@mui/material';
import CustomTable from '../../../../components/CustomTable/CustomTable';
import DeactivateModal from '../../../../components/DeactivateModal/DeactivateModal';
import DropdownMenu from '../../../../components/DropdownMenu/DropdownMenu';
import LessonDetailsForm from '../LessonDetailsForm';
import ConfirmLessonDelete from '../ConfirmLessonDelete';
import {GlobalLoaderContext} from '../../../../globalContext/globalLoader/globalLoaderProvider';
import {
  getSectionsLesson,
  updateLessonStatus,
} from '../../../../store/actions/lessonActions';
import {
  editContentButtonsAvailable,
  formatDate,
  getIslandStatusText,
  getLessonStatus,
  getLocalizedMessage,
} from '../../../../utilityFunction/helper';
import {
  ADD_LESSON_CONTENT_PATH,
  VIEW_LESSON_CONTENTS,
} from '../../../../constants/routePaths';
import {commonStyle} from '../../../../Style/commonStyle';
import moment from 'moment';
import useUserRole from '../../../../hooks/useUserRole';
import './ManageLessonsListingStyle.scss';

export default function ManageLessonListing() {
  const intl = useIntl();
  const {state} = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const {isAuthenticated} = useSelector(state => state?.auth);
  const {sectionLessonsList} = useSelector(state => state?.lesson);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
  const [openLessonDetailsModal, setOpenLessonDetailModal] = useState(false);
  const [isEditView, setIsEditView] = useState(false);
  const {sectionName, sectionId} = state;
  const userRole = useUserRole();
  const isEditAvailable = editContentButtonsAvailable(userRole);

  // Confirm Delete
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);

  useEffect(() => {
    if (isAuthenticated && sectionId) fetchSectionLessons();
  }, [isAuthenticated, sectionId, currentPage]);

  const fetchSectionLessons = async () => {
    await dispatch(
      getSectionsLesson({
        loaderDispatch,
        sectionId,
        currentPage,
        isPaginated: true,
      }),
    );
  };

  const onPageChange = page => {
    setCurrentPage(page);
  };

  const handleMenuItemClick = lesson => {
    if (selectedLesson?.id === lesson?.id) setSelectedLesson(null);
    else setSelectedLesson(lesson);
  };

  const onDeactivateConfirm = async () => {
    if (selectedLesson?.id) {
      setOpenDeactivateModal(false);
      dispatch(updateLessonStatus({id: selectedLesson?.id, intl})).then(() => {
        setSelectedLesson(null);
        fetchSectionLessons();
      });
    }
  };

  const onEditDetailsHandler = () => {
    setIsEditView(true);
    setOpenLessonDetailModal(true);
  };

  const onDeleteModuleHandler = lessonId => {
    setIsConfirmDelete(true);
    setOpenConfirmDeleteModal(true);
  };

  const handleAddNewLesson = () => {
    setIsEditView(false);
    setOpenLessonDetailModal(true);
  };

  const editLessonContent = lesson => {
    navigate(ADD_LESSON_CONTENT_PATH, {
      state: {
        isEditContent: true,
        sectionId: lesson?.section?.id,
        lessonId: lesson?.id,
        lessonBasicDetails: lesson,
        sectionName: lesson?.section?.name,
      },
    });
  };

  const seeLessonContentsHandler = lesson => {
    if (!isEditAvailable && lesson) {
      navigate(VIEW_LESSON_CONTENTS, {
        state: {lessonInfo: lesson, isStaffOrSchoolAdmin: true},
      });
    }
  };

  const TABLE_HEAD = [
    {
      id: 'id',
      label: intl.formatMessage({id: 'label.s_no'}),
      alignRight: false,
      minWidth: '10%',
      renderColumn: (lesson, index) => {
        const calculatedIndex = (currentPage - 1) * 10 + index + 1;
        return (
          <Typography variant="subtitle">{`${calculatedIndex}.`}</Typography>
        );
      },
    },
    {
      id: 'lessonName',
      label: intl.formatMessage({id: 'label.lessonsName'}),
      alignRight: false,
      renderColumn: lesson => {
        return (
          <div
            key={lesson?.id}
            className={
              isEditAvailable
                ? 'lesson_name_desc-disable d-flex'
                : 'lesson_name_desc-enable d-flex'
            }
            onClick={() => seeLessonContentsHandler(lesson)}>
            <div>
              <div className="l_name">{lesson?.name}</div>
              <div className="l-shortDesc">{lesson?.short_description}</div>
            </div>
          </div>
        );
      },
    },
    {
      id: 'status',
      label: intl.formatMessage({id: 'label.status'}),
      alignRight: false,
      renderColumn: lesson => {
        return (
          <div className="d-flex actions-wrapper">
            <div
              className={`${
                lesson?.status === 'draft' ? 'lesson-draft' : 'lesson-published'
              }`}>
              {getLessonStatus(lesson?.status, intl)}
            </div>
          </div>
        );
      },
    },
    {
      id: 'created_at',
      label: intl.formatMessage({id: 'label.created'}),
      alignRight: false,
      renderColumn: lesson => {
        return (
          <Typography variant="subtitle">
            {lesson.created_at
              ? moment(lesson.created_at).format('DD/MM/YYYY')
              : '-'}
          </Typography>
        );
      },
    },
    {
      id: 'updated_at',
      label: intl.formatMessage({id: 'label.last_edited'}),
      alignRight: false,
      renderColumn: lesson => {
        return (
          <Typography variant="subtitle">
            {lesson.updated_at
              ? moment(lesson.updated_at).format('DD/MM/YYYY')
              : '-'}
          </Typography>
        );
      },
    },
    {
      id: 'quickActions',
      alignRight: false,
      minWidth: '45%',
      renderColumn: lesson => {
        return (
          <div className="d-flex actions-wrapper">
            <div className="quickActions-wrapper">
              {isEditAvailable && (
                <div className="actions">
                  <button
                    onClick={() => editLessonContent(lesson)}
                    style={commonStyle.lessonEditButton}>
                    {intl.formatMessage({id: 'label.editModule'})}
                  </button>
                </div>
              )}

              <div className="actions">
                <img src="/images/preview.svg" alt="preview" />
              </div>

              {isEditAvailable && (
                <div
                  className={
                    lesson?.id === selectedLesson?.id
                      ? 'edit-delete selected-action'
                      : 'edit-delete'
                  }>
                  <div style={{position: 'relative'}}>
                    <PiDotsThreeVertical
                      onClick={() => handleMenuItemClick(lesson)}
                    />
                    {lesson?.id === selectedLesson?.id && (
                      <DropdownMenu isOpen={true}>
                        <div
                          className="pointer"
                          onClick={() => onEditDetailsHandler()}>
                          {intl.formatMessage({id: 'label.edit_details'})}
                        </div>

                        <div
                          className="pointer"
                          onClick={() => onDeleteModuleHandler(lesson.id)}>
                          {intl.formatMessage({id: 'label.delete_module'})}
                        </div>

                        <div
                          className="pointer"
                          onClick={() => setOpenDeactivateModal(true)}>
                          {getIslandStatusText(lesson?.is_active, intl)}
                        </div>
                      </DropdownMenu>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="d-flex module-list-wrapper">
        <div className="module-label">
          {getLocalizedMessage(intl, 'label.lessonDetails', {
            sectionName: sectionName,
          })}
        </div>
        {isEditAvailable && (
          <div>
            <button className="btn btn-primary" onClick={handleAddNewLesson}>
              <img src={'/images/white-add.svg'} alt={'Add lesson'} />
              <span className="ms-2">
                {intl.formatMessage({id: 'label.addNewLesson'})}
              </span>
            </button>
          </div>
        )}
      </div>

      <CustomTable
        tableHead={TABLE_HEAD}
        tableData={sectionLessonsList?.data}
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalPages={sectionLessonsList?.last_page}
        totalCount={sectionLessonsList?.total}
        sectionId={sectionId}
        sortable
      />

      {openLessonDetailsModal && (
        <LessonDetailsForm
          open={openLessonDetailsModal}
          setOpen={setOpenLessonDetailModal}
          lessonData={selectedLesson}
          setSelectedLesson={setSelectedLesson}
          isEditView={isEditView}
          sectionId={sectionId}
          setIsEditView={setIsEditView}
          sectionName={sectionName}
        />
      )}

      {openConfirmDeleteModal && (
        <ConfirmLessonDelete
          open={openConfirmDeleteModal}
          setOpen={setOpenConfirmDeleteModal}
          lessonData={selectedLesson}
          setSelectedLesson={setSelectedLesson}
          isConfirmDelete={isConfirmDelete}
          setIsConfirmDelete={setIsConfirmDelete}
          sectionId={sectionId}
        />
      )}

      {openDeactivateModal && (
        <DeactivateModal
          open={openDeactivateModal}
          setOpen={setOpenDeactivateModal}
          handleDeactivate={onDeactivateConfirm}
          confirmText={getIslandStatusText(selectedLesson?.is_active, intl)}
          modalContentText={
            !selectedLesson?.is_active
              ? getLocalizedMessage(intl, 'lesson.statusModal.enable')
              : getLocalizedMessage(intl, 'lesson.statusModal.disable')
          }
        />
      )}
    </div>
  );
}
