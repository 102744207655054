import React, {useEffect, useState, useContext} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {
  MANAGE_STUDENT_TROPICAL_SECTION__PATH,
  MANAGE_STUDENT_URBAN_SECTION__PATH,
  MANAGE_STUDENT_RURAL_SECTION__PATH,
  MANAGE_STUDENT_FANTASY_SECTION__PATH,
} from '../../../constants/routePaths';
import {useNavigate} from '../../../routes';
import {getIslandsList} from '../../../store/actions/islandActions';
import {GlobalLoaderContext} from '../../../globalContext/globalLoader/globalLoaderProvider';
import StudentIslandCard from '../../../components/StudentIslandCard/StudentIslandCard';
import './StudentIslandList.scss';
import Tooltip from '../../../components/Tooltip/Tooltip';
import {getLocalStorageItem} from '../../../services/local-storage';

function StudentIslandList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const islandsList = useSelector(state => state?.island?.islandsList);
  const [hoveredIslandName, setHoveredIslandName] = useState('');

  useEffect(() => {
    fetchIslandDetails();
  }, []);

  const fetchIslandDetails = async () => {
    await dispatch(getIslandsList({loaderDispatch}));
  };

  const selectedAvatarId = getLocalStorageItem('studentIslandAvatar');

  const handleAvatarClick = (id, slug) => {
    let path = '';
    switch (id) {
      case 1:
        path = MANAGE_STUDENT_TROPICAL_SECTION__PATH.replace(':id', id);
        break;
      case 2:
        path = MANAGE_STUDENT_URBAN_SECTION__PATH.replace(':id', id);
        break;
      case 3:
        path = MANAGE_STUDENT_RURAL_SECTION__PATH.replace(':id', id);
        break;
      case 4:
        path = MANAGE_STUDENT_FANTASY_SECTION__PATH.replace(':id', id);
        break;
      default:
        break;
    }
    navigate(path, {state: {slug: slug}});
  };

  const handleIslandMouseEnter = name => {
    setHoveredIslandName(name);
  };

  const handleIslandMouseLeave = () => {
    setHoveredIslandName('');
  };

  return (
    <div className="student-island-wrapper">
      <div className="student-island-container">
        <StudentIslandCard
          islandOptions={islandsList}
          onIslandClick={(id, slug) => handleAvatarClick(id, slug)}
          onIslandMouseEnter={handleIslandMouseEnter}
          onIslandMouseLeave={handleIslandMouseLeave}
          disabled={islandsList.map(island => island.is_active)}
        />
      </div>
      {/* @TODO - Temporarily removed the Avatar feature */}
      {/* <div className="island-car-tooltip">
        <img className="islandCar" src={selectedAvatarId?.path} alt="avatar" />
        {hoveredIslandName ? (
          <Tooltip
            className="w-25"
            labelText={intl.formatMessage(
              {
                id: 'label.tooltip.continue_with_island',
              },
              {islandName: hoveredIslandName},
            )}
            otherText={intl.formatMessage({id: 'label.login-subtitle'})}
          />
        ) : null}
      </div> */}
    </div>
  );
}

export default StudentIslandList;
