/**
 * todo ref should not always be there, add in case of layout - rohit bansal - 04-12-18
 *
 */
import React, {
  useLayoutEffect,
  forwardRef,
  useCallback,
  useEffect,
} from "react";
import StyleSheet from "../StyleSheet";

const defaultStyle = {
  alignItems: "stretch",
  borderWidth: 0,
  borderStyle: "solid",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  margin: 0,
  padding: 0,
  flexShrink: 0,
  position: "relative",
};

const removeExtraDomProps = (props = {}) => {
  let {
    listKey,
    extraData,
    keyExtractor,
    fetchMore,
    activeOpacity,
    stopPropagation,
    disabled,
    ...restProps
  } = props;
  return restProps;
};

const View = forwardRef(
  (
    {
      children,
      style,
      onLayout,
      onLongPress,
      onPress,
      onPressIn,
      onPressOut,
      backgroundImage,
      disabled,
      stopPropagation,
      onContextMenu: onContextMenuProp,
      as,
      ...props
    },
    ref
  ) => {
    const _ref = React.useRef(null);

    const measure = (callback) => {
      if (_ref) {
        const { x, y, width, height } = _ref.current.getBoundingClientRect();
        callback && callback(x, y, width, height, x, y);
      }
    };

    useEffect(() => {
      if (_ref) {
        _ref.current.measure = measure;
        if (ref) {
          if (typeof ref === "function") {
            ref(_ref.current);
          } else {
            try {
              ref.current = _ref.current;
            } catch (error) {
              throw new Error(`Cannot assign value '${_ref}' to ref '${ref}'`);
            }
          }
        }
      }
    }, [_ref, ref]);

    useLayoutEffect(() => {
      if (_ref && onLayout) {
        onLayout &&
          onLayout({
            nativeEvent: {
              layout: _ref.current.getBoundingClientRect(),
            },
          });
      }
    });

    const onContextMenu = useCallback(
      (e) => {
        e && e.preventDefault && e.preventDefault();
        onLongPress && onLongPress();
        onContextMenuProp && onContextMenuProp(e);
      },
      [onLongPress, onContextMenuProp]
    );

    const onClick = useCallback(
      (e) => {
        onPress && onPress(e);
        stopPropagation !== false && e.stopPropagation && e.stopPropagation();
      },
      [onPress, stopPropagation]
    );

    let restProps = removeExtraDomProps(props);
    let extraProps = {};
    if (!disabled) {
      if (onPress) {
        extraProps.onClick = onClick;
      }
      if (onLongPress) {
        extraProps.onContextMenu = onContextMenu;
      }
      if (onPressIn) {
        extraProps.onPointerDown = onPressIn;
      }
      if (onPressOut) {
        extraProps.onPointerUp = onPressOut;
        extraProps.onPointerLeave = onPressOut;
      }
    }
    if (Array.isArray(style)) {
      style = StyleSheet.flatten(style);
    }

    let extraStyle = {};
    if (backgroundImage) {
      extraStyle.backgroundImage = `url(${backgroundImage})`;
    }
    const Element = as && typeof as === "string" ? as : "div";

    return (
      <Element
        {...restProps}
        {...extraProps}
        ref={_ref}
        style={{ ...defaultStyle, ...style, ...extraStyle }}
      >
        {children}
      </Element>
    );
  }
);

export default View;
