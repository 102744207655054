import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {showToast} from '../../components/Toast/Toast';
import {setLoading} from '../../globalContext/globalLoader/globalLoaderAction';

export const addStaticContent = createAsyncThunk(
  'staticContent/addStaticContent',
  async ({payload, intl}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.post(
        '/static-contents',
        payload,
      );
      showToast(
        getLocalizedMessage(intl, 'success.pdf_added_success'),
        'success',
      );

      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        showToast(error.response.data.message);
        throw error;
      }
    }
  },
);

export const fetchStaticContent = createAsyncThunk(
  'staticContent/fetchStaticContent',
  async (
    {pageName, currentPage, isPaginated = true, loaderDispatch},
    thunkAPI,
  ) => {
    loaderDispatch(setLoading(true));
    try {
      const params = {
        page_name: pageName,
        ...(isPaginated && {per_page: 10, page: currentPage}),
      };

      const response = await axiosPrivateInstance.get('/static-contents', {
        params,
      });
      loaderDispatch(setLoading(false));
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      const errorMessage = error.response?.data?.message;
      throw errorMessage;
    }
  },
);

export const updateStaticContentStatus = createAsyncThunk(
  'staticContent/updateStaticContentStatus',
  async ({id, intl}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.patch(
        `/static-contents/status/${id}`,
      );
      showToast(
        getLocalizedMessage(intl, 'success.pdf_status_updated'),
        'success',
      );
      return response.data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        showToast(error.response.data.message);
        throw error.response.data.message;
      } else {
        showToast(
          getLocalizedMessage(intl, 'error.somethingWentWrong'),
          'error',
        );
        throw error;
      }
    }
  },
);

export const sortStaticContents = createAsyncThunk(
  'staticContent/sortStaticContents',
  async ({payload, intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.post(
        '/static-content/sort_order',
        payload,
      );
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'success.lessonSorted'), 'success');
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);
