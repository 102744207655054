import {useRef, useState, useEffect} from 'react';

const SYSTEM_COLOR_MODES = ['light', 'dark']; // currently all system supports only two modes. Need to update if browser support for extra color scheme

/**
 * Check if JS media query matches the query string passed
 */

function getCurrentColorScheme() {
  const QUERIES = {};
  for (let scheme of SYSTEM_COLOR_MODES) {
    const query = QUERIES.hasOwnProperty(scheme)
      ? QUERIES[scheme]
      : (QUERIES[scheme] = window.matchMedia(
          `(prefers-color-scheme: ${scheme})`,
        ));

    if (query.matches) return {query, scheme};
  }
  return {};
}
// check on system preference if it can't find any use fallback
export const useSystemColorMode = () => {
  const isMounted = useRef();
  const colorScheme = useRef();

  const [scheme, setColorScheme] = useState(() => {
    const currentColorScheme = getCurrentColorScheme();
    colorScheme.current = currentColorScheme;
    const {scheme} = currentColorScheme;
    return scheme;
  });

  useEffect(() => {
    const {query} = colorScheme.current;
    if (query) {
      query.addEventListener('change', schemeChangeHandler);
      isMounted.current = true;

      function schemeChangeHandler(evt) {
        if (!evt.matches) {
          const {
            query: newQuery,
            scheme,
          } = (colorScheme.current = getCurrentColorScheme());
          isMounted.current && setColorScheme(scheme);
          if (newQuery) {
            this.removeEventListener('change', schemeChangeHandler);
            newQuery.addEventListener('change', schemeChangeHandler);
          }
        }
      }
      return () => {
        const {query} = colorScheme.current;
        query.removeEventListener('change', schemeChangeHandler);
        isMounted.current = false;
      };
    }
  }, []);

  return scheme;
};
