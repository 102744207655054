import React from "react";
import View from "../View";

class Modal extends React.Component {
  render() {
    let { isVisible } = this.props;
    if (!isVisible) {
      return null;
    }
    return (
      <View
        style={{
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          position: "fixed",
          zIndex: 1,
        }}
      >
        {this.props.children}
      </View>
    );
  }
}
export default Modal;
