import React, {useState, useEffect, useContext} from 'react';
import './StudentProfileLeftBar.scss';
import {useSelector} from 'react-redux';

import {getLocalStorageItem} from '../../../../services/local-storage';
import AvatarList from '../../AvatarList/AvatarList';
import {useIntl} from 'react-intl';
import InputLabel from '../../../../components/InputLabel/InputLabel.js';
import {
  addStudentUserProfileFormFieldNames,
  addStudentUserProfileInitialValues,
  validationSchema,
} from '../../../../validationSchema/studentUserProfileSchema.js';
import {useFormik} from 'formik';
import CustomButton from '../../../../components/CustomButton/CustomButton.js';
import {
  fetchUserProfile,
  updateUserProfile,
} from '../../../../store/actions/userProfileActions';
import {useDispatch} from 'react-redux';
import {GlobalLoaderContext} from '../../../../globalContext/globalLoader/globalLoaderProvider';

const StudentProfileLeftBar = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const [showAvatarList, setShowAvatarList] = useState(false);
  const selectedAvatarId = getLocalStorageItem('studentIslandAvatar');
  const userId = useSelector(state => state?.auth?.data?.data?.data?.user?.id);

  const handlePencilClick = () => {
    setShowAvatarList(true);
  };
  const handleAvatarSelection = () => {
    setTimeout(() => {
      setShowAvatarList(false);
    }, 1000);
  };

  const {
    values,
    handleChange,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues: addStudentUserProfileInitialValues,
    onSubmit: async values => {
      const payload = {
        [addStudentUserProfileFormFieldNames.FIRST_NAME]: values.first_name,
        [addStudentUserProfileFormFieldNames.LAST_NAME]: values.last_name,
        [addStudentUserProfileFormFieldNames.EMAIL]: values.email,
      };

      if (userId) {
        await dispatch(
          updateUserProfile({
            id: userId,
            userData: payload,
            loaderDispatch,
            intl,
          }),
        ).then(() => {
          fetchData();
        });
      }
    },
    validationSchema: validationSchema(intl),
  });

  const fetchData = async () => {
    if (userId) {
      try {
        const response = await dispatch(
          fetchUserProfile({id: userId, loaderDispatch}),
        );
        const userProfileData = response.payload.data;
        const teacherName = userProfileData.teacher.first_name;
        const counsellorName = userProfileData.counsellor.first_name;
        setValues(prev => ({
          ...prev,

          [addStudentUserProfileFormFieldNames.FIRST_NAME]:
            userProfileData.first_name,
          [addStudentUserProfileFormFieldNames.LAST_NAME]:
            userProfileData.last_name,
          [addStudentUserProfileFormFieldNames.EMAIL]: userProfileData.email,
          [addStudentUserProfileFormFieldNames.TEACHER]: teacherName,
          [addStudentUserProfileFormFieldNames.COUNSELLOR]: counsellorName,
        }));
      } catch (error) {
        console.log('Error fetching student data:', error);
      }
    }
  };

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  return (
    <div>
      {showAvatarList ? (
        <AvatarList
          onAvatarSelect={handleAvatarSelection}
          className="profile-avatar py-3"
          buttonText={intl.formatMessage({id: 'label.save'})}
        />
      ) : (
        <>
          {/* @TODO - Temporarily removed the Avatar feature */}
          {/* <div className="student-avatar-display">
            <div className="student-avatar-display-inner">
              <img
                className="edit"
                src="/images/pencil.png"
                onClick={handlePencilClick}
              />
              <img className="" src={selectedAvatarId?.path} alt="avatar" />
            </div>
          </div> */}
          <div className="student-form p-4">
            <div>
              <div className="container">
                <div className="">
                  <div className="row">
                    <div className="col-md-5">
                      <label>
                        {intl.formatMessage({id: 'label.first_name'})}{' '}
                      </label>
                    </div>
                    <div className="mb-2 col-md-7 cus-student-label editable">
                      <InputLabel
                        name={addStudentUserProfileFormFieldNames.FIRST_NAME}
                        placeholder={intl.formatMessage({
                          id: 'label.enter_first_name',
                        })}
                        value={
                          values[addStudentUserProfileFormFieldNames.FIRST_NAME]
                        }
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched[
                            addStudentUserProfileFormFieldNames.FIRST_NAME
                          ] &&
                          errors[addStudentUserProfileFormFieldNames.FIRST_NAME]
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5">
                      <label>
                        {' '}
                        {intl.formatMessage({id: 'label.last_name'})}
                      </label>
                    </div>
                    <div className="mb-2 col-md-7 cus-student-label editable">
                      <InputLabel
                        name={addStudentUserProfileFormFieldNames.LAST_NAME}
                        placeholder={intl.formatMessage({
                          id: 'label.enter_last_name',
                        })}
                        value={
                          values[addStudentUserProfileFormFieldNames.LAST_NAME]
                        }
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched[
                            addStudentUserProfileFormFieldNames.LAST_NAME
                          ] &&
                          errors[addStudentUserProfileFormFieldNames.LAST_NAME]
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5">
                      <label>
                        {' '}
                        {intl.formatMessage({id: 'label.email_address'})}
                      </label>
                    </div>
                    <div className="mb-2 col-md-7 cus-student-label">
                      <InputLabel
                        name={addStudentUserProfileFormFieldNames.EMAIL}
                        value={
                          values[addStudentUserProfileFormFieldNames.EMAIL]
                        }
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-5">
                      <label>
                        {' '}
                        {intl.formatMessage({id: 'label.teacher'})}
                      </label>
                    </div>
                    <div className="mb-2 col-md-7 cus-student-label">
                      <InputLabel
                        name={addStudentUserProfileFormFieldNames.TEACHER}
                        value={
                          values[addStudentUserProfileFormFieldNames.TEACHER]
                        }
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-5">
                      <label>
                        {' '}
                        {intl.formatMessage({id: 'label.counsellor'})}
                      </label>
                    </div>
                    <div className="mb-2 col-md-7 cus-student-label">
                      <InputLabel
                        name={addStudentUserProfileFormFieldNames.COUNSELLOR}
                        value={
                          values[addStudentUserProfileFormFieldNames.COUNSELLOR]
                        }
                      />
                    </div>
                  </div>
                </div>
                <CustomButton
                  labelText={intl.formatMessage({id: 'label.save'})}
                  handleClick={handleSubmit}
                  variant="blue"
                  align="right"
                  type="submit"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StudentProfileLeftBar;
