import React, {useState, useEffect, useContext} from 'react';
import './AddSchool.scss';
import {useDispatch} from 'react-redux';
import {
  addSchoolData,
  fetchSchoolById,
  updateSchool,
} from '../../store/actions/schoolActions';
import SchoolDetailForm from './SchoolDetailForm';
import AdminForm from './AdminForm';
import SettingsForm from './SettingsForm';
import ProgressBar from './ProgressBar';
import {
  fetchCountriesData,
  fetchStatesData,
  fetchDistrictData,
  fetchDesignationsData,
} from '../../services/auth-service';
import {useParams} from 'react-router-dom';
import {useIntl} from 'react-intl';
import moment from 'moment';
import {useNavigate} from '../../routes';
import {MANAGE_SCHOOLS_PATH} from '../../constants/routePaths';
import CustomButton from '../../components/CustomButton/CustomButton';
import {EMAIL_REGEX, MOBILE_NUMBER_REGEX} from '../../core/validation';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';
import useUserRole from '../../hooks/useUserRole';
import UserRoles from '../../constants/userRoles';

function SchoolForm() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const [step, setStep] = useState(1);
  const [schoolDetails, setSchoolDetails] = useState({});
  const [adminDetails, setAdminDetails] = useState({});
  const [settingsDetails, setSettingsDetails] = useState({});
  const [designations, setDesignations] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const dispatch = useDispatch();
  const {id} = useParams();
  const [schoolId, setSchoolId] = useState(null);
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [showEditButton, setShowEditButton] = useState(true);
  const [fileId, setFileId] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [imageName, setImageName] = useState('');
  const userRole = useUserRole();

  const fetchData = async () => {
    if (id) {
      dispatch(fetchSchoolById(id))
        .then(response => {
          const schoolData = response.payload.data;
          setPreviewUrl(schoolData?.media?.path);
          setFileId(schoolData?.media?.id);
          setImageName(schoolData?.media?.name);
          setSchoolDetails({
            name: schoolData.name,
            district_id: schoolData.district.id,
            website_url: schoolData.website_url,
            address: schoolData.address,
            country_id: schoolData.country.id,
            state_id: schoolData.state.id,
            zip_code: schoolData.zip_code,
            contact_first_name: schoolData.contact_first_name,
            contact_last_name: schoolData.contact_last_name,
            contact_email: schoolData.contact_email,
            contact_designation_id: schoolData.designation.id,
            contact_phone_no: schoolData.contact_phone_no,
            contact_alternate_no: schoolData.contact_alternate_no,
            city: schoolData.city,
            media_id: fileId,
          });
          setAdminDetails({
            admin_first_name: schoolData.admin.first_name,
            admin_last_name: schoolData.admin.last_name,
            admin_email: schoolData.admin.email,
            admin_contact_no: schoolData.admin.contact_no,
            admin_alternate_no: schoolData.admin.alternate_phone_no,
          });
          setSettingsDetails({
            recieve_new_contact_announcements:
              schoolData.recieve_new_contact_announcements,
            recieve_task_reminder: schoolData.recieve_task_reminder,
            recieve_weekly_progress: schoolData.recieve_weekly_progress,
            license_expiry_date: schoolData.license_expiry_date,
            license_start_date: schoolData.license_start_date,
            student_limit: schoolData.student_limit,
            school_admin_limit: schoolData.school_admin_limit,
            teacher_limit: schoolData.teacher_limit,
            counselor_limit: schoolData.counselor_limit,
            pilot_program: schoolData.pilot_program,
          });
        })
        .catch(error => {
          console.log('Error fetching school data:', error);
        });
    }
  };

  useEffect(() => {
    if (id) {
      setEditMode(false);
      fetchData();
    }
  }, [id]);
  const toggleEditMode = () => {
    setEditMode(prev => !prev);
    setShowEditButton(false);
  };

  useEffect(() => {
    let isMounted = true;
    fetchCountriesData(dispatch, setCountries);
    fetchDesignationsData(dispatch, setDesignations);
    return () => {
      isMounted = false;
    };
  }, []);

  const handleInputChange = async (e, formType) => {
    const {name, value, files} = e.target;
    let parsedValue = value;

    if (
      [
        'contact_phone_no',
        'contact_designation_id',
        'admin_designation_id',
      ].includes(name)
    ) {
      parsedValue =
        value.trim() === '' || isNaN(value) ? null : parseInt(value);
    }

    if (formType === 'school') {
      if (name === 'country_id') {
        setSchoolDetails(prevSchoolDetails => ({
          ...prevSchoolDetails,
          district_id: '',
          state_id: '',
          [name]: parsedValue,
        }));
        fetchStatesData(dispatch, parsedValue, setStates);
      } else if (name === 'state_id') {
        setSchoolDetails(prevSchoolDetails => ({
          ...prevSchoolDetails,
          district_id: '',
          [name]: parsedValue,
        }));
        await fetchDistrictData({
          dispatch,
          parsedValue,
          setDistricts,
          loaderDispatch,
        });
      } else {
        setSchoolDetails(prevSchoolDetails => ({
          ...prevSchoolDetails,
          [name]: parsedValue,
        }));
      }
    } else if (formType === 'school_admin') {
      setAdminDetails(prevAdminDetails => ({
        ...prevAdminDetails,
        [name]: parsedValue,
      }));
    } else if (formType === 'settings') {
      if (name !== 'license_start_date' && name !== 'license_expiry_date') {
        setSettingsDetails(prevSettingsDetails => ({
          ...prevSettingsDetails,
          [name]: Number(parsedValue),
        }));
      }
    }
  };

  const handleDateChange = (date, formType) => {
    if (formType === 'settings') {
      setSettingsDetails(prevSettingsDetails => ({
        ...prevSettingsDetails,
        [date.type === 'start' ? 'license_start_date' : 'license_expiry_date']:
          date.value,
      }));
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      let isValid = true;
      if (step === 1) {
        isValid = validateSchoolDetails(schoolDetails, step);
      } else if (step === 2) {
        isValid = validateAdminDetails(adminDetails, step);
      } else if (step === 3) {
        isValid = validateSettings(settingsDetails);
      }
      if (!isValid) {
        return;
      }

      if (!editMode && id) {
        if (step < 3) {
          setStep(step + 1);
        }
        return;
      }

      if (id) {
        handleUpdateForm(
          step === 1
            ? schoolDetails
            : step === 2
            ? adminDetails
            : settingsDetails,
          step === 1 ? 'school' : step === 2 ? 'school_admin' : 'settings',
        );
      }
      let payload = {};

      if (step === 1) {
        payload = {...schoolDetails};
      } else if (step === 2) {
        payload = {
          ...schoolDetails,
          ...adminDetails,
        };
      } else if (step === 3) {
        payload = {...settingsDetails};
      }

      if (!id) {
        const response = await dispatch(
          addSchoolData({
            schoolData: {...payload, media_id: fileId},
            intl,
            setStep,
          }),
        );
        if (response?.payload?.data?.school_id) {
          setSchoolId(response.payload.data.school_id);
        }
      }
    } catch (error) {
      console.error('Error while adding school data:', error);
    }
  };

  const handleUpdateForm = async (payload, page) => {
    let updatedPayload = {
      ...payload,
      page: page,
      media_id: fileId,
      pilot_program: payload.pilot_program ? 1 : 0,
    };
    console.log(updatedPayload, 'updatedPayload');
    try {
      if (id) {
        await dispatch(updateSchool({id: id, payload: updatedPayload, intl}));
      }
      if (step < 3) {
        setStep(step + 1);
      }
    } catch (error) {
      console.log('Error while updating school data:', error);
      throw error;
    }
  };

  const handleSchoolSubmit = e => {
    e.preventDefault();

    const isValid = validateSchoolDetails(schoolDetails, step);
    if (!isValid) {
      return;
    }

    setStep(2);
  };

  const handleRegister = async e => {
    e.preventDefault();
    try {
      const payload = {
        ...settingsDetails,
        page: 'settings',
      };
      await dispatch(updateSchool({id: schoolId, payload: payload, intl}));
      navigate(MANAGE_SCHOOLS_PATH);
    } catch (error) {
      console.log('Error while registering:', error);
    }
  };

  const handleCancel = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const validateSchoolDetails = (schoolDetails, step) => {
    const errors = {};
    const requiredFields = [
      {key: 'name', minLength: 3},
      {key: 'website_url'},
      {key: 'address', minLength: 5},
      {key: 'zip_code'},
      {key: 'contact_first_name', minLength: 3},
      {key: 'contact_last_name', minLength: 3},
      {key: 'contact_email'},
      {key: 'contact_phone_no'},
    ];

    requiredFields.forEach(({key, minLength}) => {
      let value = schoolDetails[key];
      if (typeof value === 'string') {
        value = value.trim();
      }
      if (step === 1) {
        if (!value || value.length === 0) {
          errors[key] = intl.formatMessage({id: 'label.fieldError.empty'});
        } else if (minLength && value.length < minLength) {
          errors[key] = intl.formatMessage(
            {id: 'label.fieldError.minLength'},
            {minLength},
          );
        }
      }
    });

    if (step === 1 && !schoolDetails.name) {
      errors.name = intl.formatMessage({
        id: 'label.fieldError.school-name.required',
      });
    }
    if (step === 1 && !schoolDetails.website_url) {
      errors.website_url = intl.formatMessage({
        id: 'label.fieldError.website-url.required',
      });
    }
    if (step === 1 && !schoolDetails.country_id) {
      errors.country_id = intl.formatMessage({
        id: 'label.fieldError.country.required',
      });
    }
    if (step === 1 && !schoolDetails.state_id) {
      errors.state_id = intl.formatMessage({
        id: 'label.fieldError.state.required',
      });
    }

    if (step === 1 && !schoolDetails.district_id) {
      errors.district_id = intl.formatMessage({
        id: 'label.fieldError.district.required',
      });
    }

    if (step === 1 && !schoolDetails.address) {
      errors.address = intl.formatMessage({
        id: 'label.fieldError.address.required',
      });
    }
    if (step === 1 && !schoolDetails.zip_code) {
      errors.zip_code = intl.formatMessage({
        id: 'label.fieldError.zip-code.required',
      });
    }

    if (step === 1 && !schoolDetails.contact_first_name) {
      errors.contact_first_name = intl.formatMessage({
        id: 'label.fieldError.first-name.required',
      });
    }

    if (step === 1 && !schoolDetails.contact_last_name) {
      errors.contact_last_name = intl.formatMessage({
        id: 'label.fieldError.last-name.required',
      });
    }
    if (step === 1 && !EMAIL_REGEX.test(schoolDetails.contact_email)) {
      errors.contact_email = intl.formatMessage({
        id: 'label.fieldError.email.required',
      });
    }

    if (
      step === 1 &&
      !MOBILE_NUMBER_REGEX.test(schoolDetails.contact_phone_no)
    ) {
      errors.contact_phone_no = intl.formatMessage({
        id: 'label.fieldError.phone-number.required',
      });
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validateAdminDetails = (adminDetails, step) => {
    const errors = {};

    const requiredFields = [
      {key: 'admin_first_name', minLength: 3},
      {key: 'admin_last_name', minLength: 3},
      {key: 'admin_email'},
      {key: 'admin_contact_no'},
    ];

    requiredFields.forEach(({key, minLength}) => {
      let value = adminDetails[key];
      if (typeof value === 'string') {
        value = value.trim();
      }

      if (step === 2) {
        if (!value || value.length === 0) {
          errors[key] = intl.formatMessage({id: 'label.fieldError.empty'});
        } else if (minLength && value?.length < minLength) {
          errors[key] = intl.formatMessage(
            {id: 'label.fieldError.minLength'},
            {minLength},
          );
        }
      }
    });

    if (step === 2 && !adminDetails.admin_first_name) {
      errors.admin_first_name = intl.formatMessage({
        id: 'label.fieldError.school-name.required',
      });
    }

    if (step === 2 && !adminDetails.admin_last_name) {
      errors.admin_last_name = intl.formatMessage({
        id: 'label.fieldError.last-name.required',
      });
    }
    if (step === 2 && !EMAIL_REGEX.test(adminDetails.admin_email)) {
      errors.admin_email = intl.formatMessage({
        id: 'label.fieldError.email.required',
      });
    }

    if (
      step === 2 &&
      !MOBILE_NUMBER_REGEX.test(adminDetails.admin_contact_no)
    ) {
      errors.admin_contact_no = intl.formatMessage({
        id: 'label.fieldError.phone-number.required',
      });
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validateSettings = settingsDetails => {
    const errors = {};

    const requiredFields = [
      {key: 'student_limit'},
      {key: 'school_admin_limit'},
      {key: 'teacher_limit'},
      {key: 'counselor_limit'},
    ];

    requiredFields.forEach(({key}) => {
      let value = settingsDetails[key];
      if (typeof value === 'string') {
        value = value.trim();
      }

      if (!value || value.length === 0) {
        errors[key] = intl.formatMessage({id: 'label.fieldError.empty'});
      } else if (value < 1) {
        errors[key] = intl.formatMessage({id: 'label.fieldError.minValue'});
      }
    });

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <div>
      <div class="container">
        <div class="row">
          <div className="p-3">
            {showEditButton && id && userRole == [UserRoles.SUPER_ADMIN] && (
              <CustomButton
                labelText={intl.formatMessage({id: 'label.edit'})}
                handleClick={toggleEditMode}
                variant="blue"
                align="right"
              />
            )}
          </div>
        </div>
      </div>
      <ProgressBar
        currentStep={step}
        allSteps={['School Details', 'Admin Details', 'Settings']}
      />
      <form
        onSubmit={
          id
            ? handleSubmit
            : step === 1
            ? handleSchoolSubmit
            : step === 3
            ? handleRegister
            : handleSubmit
        }>
        {step === 1 && (
          <SchoolDetailForm
            formData={schoolDetails}
            onChange={e => handleInputChange(e, 'school')}
            onSubmit={handleSubmit}
            countries={countries}
            states={states}
            errors={errors}
            editMode={editMode}
            id={id}
            setPreviewUrl={setPreviewUrl}
            setFileId={setFileId}
            previewUrl={previewUrl}
            imageName={imageName}
            setImageName={setImageName}
          />
        )}
        {step === 2 && (
          <AdminForm
            formData={adminDetails}
            onChange={e => handleInputChange(e, 'school_admin')}
            onSubmit={handleSubmit}
            errors={errors}
            editMode={editMode}
            id={id}
          />
        )}
        {step === 3 && (
          <SettingsForm
            formData={settingsDetails}
            onDateChange={(date, type) =>
              handleDateChange({value: date, type}, 'settings')
            }
            onSubmit={handleSubmit}
            setFormData={setSettingsDetails}
            onChange={e => handleInputChange(e, 'settings')}
            editMode={editMode}
            id={id}
            errors={errors}
          />
        )}
        <div className="container">
          <div className="row">
            <div className="schoolFormBtn px-4">
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={handleCancel}>
                {intl.formatMessage({id: 'label.cancel'})}
              </button>
              {step < 3 && (
                <button type="submit" className="btn btn-primary">
                  {id && !editMode
                    ? intl.formatMessage({id: 'label.next'})
                    : intl.formatMessage({id: 'label.save-next'})}
                </button>
              )}
              {step == 3 && !id && (
                <button type="submit" className="btn btn-primary">
                  {intl.formatMessage({id: 'label.register'})}
                </button>
              )}
              {step == 3 && id && (
                <button
                  type="submit"
                  disabled={!editMode}
                  className="btn btn-primary">
                  {intl.formatMessage({id: 'label.save'})}
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default SchoolForm;
