import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {setLoading} from '../../globalContext/globalLoader/globalLoaderAction';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {showToast} from '../../components/Toast/Toast';
import {MANAGE_LESSONS_PATH} from '../../constants/routePaths';
import {setLocalStorageItem} from '../../services/local-storage';

export const getSectionsLesson = createAsyncThunk(
  'content/island/fetchSectionsLesson',
  async (
    {loaderDispatch, sectionId, currentPage, isPaginated = true},
    thunkAPI,
  ) => {
    loaderDispatch(setLoading(true));
    try {
      const params = {
        section_id: sectionId,
        ...(isPaginated && {per_page: 10, page: currentPage}),
      };
      const response = await axiosPrivateInstance.get('lessons', {params});
      loaderDispatch(setLoading(false));
      return response?.data?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      throw error.response.data.message;
    }
  },
);

export const updateLessonStatus = createAsyncThunk(
  'content/island/updateLessonStatus',
  async ({id, intl}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.patch(`/lesson/status/${id}`);
      showToast(getLocalizedMessage(intl, 'module.success.updated'), 'success');
      return response.data;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const editLessonDetails = createAsyncThunk(
  'content/island/editLesson',
  async ({payload, lessonId, intl}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.patch(
        `/lessons/${lessonId}`,
        payload,
      );
      showToast(
        getLocalizedMessage(intl, 'success.lessonDetailsUpdated'),
        'success',
      );
      return response.data;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const addLessonContentData = createAsyncThunk(
  'content/island/addLessonContent',
  async (
    {payload, intl, sectionName, sectionId, navigate, loaderDispatch, dispatch},
    thunkAPI,
  ) => {
    try {
      const response = await axiosPrivateInstance.post('/lessons', payload);
      navigate(MANAGE_LESSONS_PATH, {
        state: {
          sectionName: sectionName,
          sectionId: sectionId,
        },
      });
      dispatch(getSectionsLesson({loaderDispatch, sectionId}));
      showToast(
        getLocalizedMessage(intl, 'success.newLessonCreated'),
        'success',
      );
      return response.data;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const getLessonContentData = createAsyncThunk(
  'content/island/getLessonContent',
  async ({loaderDispatch, lessonId, status}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const params = {status: status};
      const response = await axiosPrivateInstance.get(`lessons/${lessonId}`, {
        params,
      });
      loaderDispatch(setLoading(false));
      return response?.data?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      throw error.response.data.message;
    }
  },
);

export const updateLessonContentData = createAsyncThunk(
  'content/island/updateLessonContent',
  async (
    {
      payload,
      lessonId,
      intl,
      sectionName,
      sectionId,
      navigate,
      loaderDispatch,
      dispatch,
    },
    thunkAPI,
  ) => {
    try {
      const response = await axiosPrivateInstance.patch(
        `/lessons/${lessonId}`,
        payload,
      );
      navigate(MANAGE_LESSONS_PATH, {
        state: {
          sectionName: sectionName,
          sectionId: sectionId,
        },
      });
      dispatch(getSectionsLesson({loaderDispatch, sectionId}));
      showToast(
        getLocalizedMessage(intl, 'success.lessonContentUpdated'),
        'success',
      );
      return response.data;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const markLessonCompleted = createAsyncThunk(
  'lesson/markCompleted',
  async ({payload, intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.post(
        'lesson/complete',
        payload,
      );
      loaderDispatch(setLoading(false));
      const earnedTokens = response?.data?.data?.earned_tokens;
      setLocalStorageItem('earned_tokens', earnedTokens);
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const deleteLesson = createAsyncThunk(
  'lesson/delete',
  async ({lessonId, intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.delete(
        `/lesson/delete-lesson/${lessonId}`,
      );
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'success.lessonDeleted'), 'success');
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const sortLessonContents = createAsyncThunk(
  'lesson/sort',
  async ({payload, intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.post(
        '/lesson/sort_order',
        payload,
      );
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'success.lessonSorted'), 'success');
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);
