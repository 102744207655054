import {useState, useEffect, useCallback} from 'react';

// A really common need is to get the current size of the browser window.
// This hook returns an object containing the window's width and height.
// If executed server-side (no window object) the value of width and height will be undefined.

// Usage
// function App() {
//   const size = useWindowDimensions();
//   return (
//     <div>
//       {size.width}px / {size.height}px
//     </div>
//   );
// }

export function useWindowDimensions() {
  const hasWindow = typeof window !== 'undefined';

  const getWindowDimensions = useCallback(() => {
    const width = hasWindow ? window?.innerWidth : null;
    const height = hasWindow ? window?.innerHeight : null;
    return {
      width,
      height,
    };
  }, [hasWindow]);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  useEffect(() => {
    if (hasWindow) {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowDimensions(getWindowDimensions());
      }
      // Add event listener
      window.addEventListener('resize', handleResize);
      if (!windowDimensions.width && !windowDimensions.height) {
        handleResize();
      }
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [
    hasWindow,
    getWindowDimensions,
    windowDimensions.width,
    windowDimensions.height,
  ]); // Empty array ensures that effect is only run on mount
  return windowDimensions;
}
