import React from "react";
import View from "../View";

class KeyboardAvoidingView extends React.Component {
  render() {
    return <View>{this.props.children}</View>;
  }
}

export default KeyboardAvoidingView;
