import React from "react";
import Text from "../Text";
import View from "../View";
import Image from "../Image";
import ScrollView from "../ScrollView";
import StyleSheet from "../StyleSheet";

let uids = {};
let animatedValueListeners = {};

const addAnimationListener = ({ value, uid, callback, viewName, key }) => {
  let valueUid = value.uid;
  animatedValueListeners[valueUid] = animatedValueListeners[valueUid] || [];
  let listener = animatedValueListeners[valueUid].find((doc) => {
    if (doc.uid === uid) {
      return true;
    }
  });
  if (!listener) {
    animatedValueListeners[valueUid].push({
      uid,
      callback,
      viewName,
      key,
      valueName: value.name,
    });
  }
};

const fireAnimationListener = ({ value }) => {
  let valueUid = value.uid;
  animatedValueListeners[valueUid] = animatedValueListeners[valueUid] || [];
  animatedValueListeners[valueUid].forEach((doc) => {
    doc.callback();
  });
};
const getUID = (key) => {
  uids[key] = uids[key] || 0;
  uids[key]++;

  return key + "_" + uids[key];
};

class Interpolate {
  constructor({ value, inputRange, outputRange }) {
    this.value = value;
    this.inputRange = inputRange;
    this.outputRange = outputRange;
  }
  getValue = ({ viewName, key }) => {
    let value = this.value;

    let currentValue = value.getValue({
      viewName,
      key,
      interpolate: "interpolate",
    });
    let outValue = currentValue;
    if (this.inputRange) {
      for (let index = 0; index < this.inputRange.length; index++) {
        if (this.inputRange[index] === currentValue) {
          outValue = this.outputRange[index];
          break;
        }
      }
    }

    return outValue;
  };
}
class Value {
  constructor(value, name) {
    this.name = name;
    this.uid = getUID("Value");
    this.value = value;
  }
  setValue = ({ toValue }) => {
    this.value = toValue;
    fireAnimationListener({ value: this });
  };
  interpolate = ({ inputRange, outputRange }) => {
    return new Interpolate({ value: this, inputRange, outputRange });
  };
  getValue = ({ viewName, key, interpolate } = {}) => {
    return this.value;
  };
}

//ValueXY is used in android for keeping value of animated having coordinates(x,y) - by Sumit on 27-11-20
class ValueXY {
  constructor(value, name) {
    let { x, y } = value || {};
    this.valueX = new Value(x, name);
    this.valueY = new Value(y, name);
    this.xOffset = 0;
    this.yOffset = 0;
    this.x = this.xOffset + this.valueX.value;
    this.y = this.yOffset + this.valueY.value;
  }

  setValue = ({ x, y }) => {
    this.valueX.setValue({ toValue: x + this.xOffset });
    this.valueY.setValue({ toValue: y + this.yOffset });
    fireAnimationListener({ valueX: this.valueX });
    fireAnimationListener({ valueY: this.valueY });
  };

  setOffset = ({ x, y }) => {
    this.xOffset = x;
    this.yOffset = y;
  };

  getLayout = () => {
    return { top: this.x, left: this.y };
  };
}

class AnimatedView extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.uid = getUID("AnimatedView");
  }
  onValueChange = () => {
    this.setState({ toggled: !this.state.toggled });
  };
  render() {
    let { style, name, ...rest } = this.props;

    if (Array.isArray(style)) {
      style = StyleSheet.flatten(style);
    }
    for (let k in style) {
      let styleValue = style[k];
      if (styleValue instanceof Value) {
        addAnimationListener({
          value: styleValue,
          uid: this.uid,
          key: k,
          viewName: this.props.name,
          callback: this.onValueChange,
        });
        let animatedValue = styleValue.getValue({ viewName: name, key: k });
        style[k] = animatedValue;
      } else if (styleValue instanceof Interpolate) {
        addAnimationListener({
          value: styleValue.value,
          uid: this.uid,
          key: k,
          viewName: this.props.name,
          callback: this.onValueChange,
        });
        let animatedValue = styleValue.getValue({ viewName: name, key: k });
        style[k] = animatedValue;
      }
    }

    return <View {...rest} style={style} />;
  }
}
class FlatList extends React.Component {
  render() {
    let {
      data = [],
      renderItem,
      style,
      ListHeaderComponent,
      ListFooterComponent,
      horizontal,
      renderLoadMore,
    } = this.props;
    if (horizontal) {
      style = { ...style, flexDirection: "row" };
    }
    let Component = ScrollView;
    if (style && !style.flex) {
      Component = View;
    }

    return (
      <Component style={style}>
        {ListHeaderComponent && ListHeaderComponent()}
        {data.map((item, index) => {
          return <View>{renderItem({ item, index })}</View>;
        })}
        {renderLoadMore && renderLoadMore()}

        {ListFooterComponent && ListFooterComponent()}
      </Component>
    );
  }
}

class SectionList extends React.Component {
  render() {
    const {
      sections = [],
      renderItem,
      style,
      renderSectionHeader,
      ListHeaderComponent,
    } = this.props;
    return (
      <ScrollView style={style}>
        {ListHeaderComponent && ListHeaderComponent()}
        {sections.map((section, index) => {
          let { data = [] } = section;
          return (
            <View>
              <View>{renderSectionHeader({ section, index })}</View>
              {data.map((item, index) => {
                return <View>{renderItem({ item, section, index })}</View>;
              })}
            </View>
          );
        })}
      </ScrollView>
    );
  }
}

let event = () => {};
let timing = (value, config) => {
  const start = (callback) => {
    setTimeout(() => {
      let { toValue } = config;
      value.setValue({ toValue });
      if (callback) {
        setTimeout(() => {
          callback({ finished: true });
        }, 100);
      }
    }, 1000);
  };
  const stop = () => {};
  return { start, stop };
};
let parallel = () => {};
let diffClamp = () => {};
let add = () => {};
let spring = () => {
  return {
    stop: () => {},
  };
};

let createAnimatedComponent = (Component) => {
  return Component;
};

let Animated = {
  View: AnimatedView,
  Text,
  ScrollView,
  FlatList,
  SectionList,
  Image,
  Value,
  ValueXY,
  event,
  timing,
  parallel,
  spring,
  diffClamp,
  add,
  createAnimatedComponent,
};

export default Animated;
