import React, {useMemo} from 'react';
import {getClosestBreakpoint} from './Utility';
import {useWindowDimensions} from './useWindowDimensions';

const MediaQueryContext = React.createContext();

export const MediaQueryProvider = ({breakpoints, children}) => {
  const windowWidth = useWindowDimensions()?.width;
  const currentBreakpoint = useMemo(
    () => getClosestBreakpoint(breakpoints, windowWidth),
    [windowWidth, breakpoints],
  );

  const providerValue = useMemo(
    () => ({
      current: Object.keys(breakpoints)[currentBreakpoint],
      width: windowWidth,
      breakpoints,
    }),
    [breakpoints, currentBreakpoint, windowWidth],
  );

  return (
    <MediaQueryContext.Provider value={providerValue}>
      {children}
    </MediaQueryContext.Provider>
  );
};

export {MediaQueryContext};
