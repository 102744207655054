import React, {useContext} from 'react';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import CustomDialogue from '../../../components/CustomDialogue/CustomDialogue';
import {getLocalizedMessage} from '../../../utilityFunction/helper';
import {GlobalLoaderContext} from '../../../globalContext/globalLoader/globalLoaderProvider';
import {
  getSectionsLesson,
  deleteLesson,
} from '../../../store/actions/lessonActions';

export default function ConfirmLessonDelete({
  open,
  setOpen,
  lessonData,
  setSelectedLesson,
  setIsConfirmDelete,
  sectionId,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);

  const closeLessonModal = () => {
    setIsConfirmDelete(false);
    setOpen(false);
    setSelectedLesson(null);
  };

  const handleDeleteModule = async () => {
    await dispatch(
      deleteLesson({
        lessonId: lessonData?.id,
        intl,
        loaderDispatch,
      }),
    );
    await dispatch(getSectionsLesson({loaderDispatch, sectionId}));
    setIsConfirmDelete(false);
    setOpen(false);
  };

  return (
    <CustomDialogue
      open={open}
      onCancel={closeLessonModal}
      cancelText={getLocalizedMessage(intl, 'label.cancel')}
      confirmText={getLocalizedMessage(intl, 'label.delete')}
      dialogueTitle={getLocalizedMessage(intl, 'label.title.confirmDelete')}
      onSubmit={handleDeleteModule}
      className={'center-btn'}>
      <div className="container">
        <div className="row mt-4">
          <div className="mb-3 col-md-12">
            <p>
              <span>Are you sure you want to delete </span>
              <span className="fw-bold">"{lessonData.name}" </span>
              <span>module</span>?
            </p>
          </div>
        </div>
      </div>
    </CustomDialogue>
  );
}
