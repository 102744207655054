export * from './CoreStyle';

export const getNumberOfLineStyle = ({numberOfLines}) => {
  let ellipsesStyle = {};
  if (numberOfLines === 1) {
    ellipsesStyle = {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    };
  } else if (numberOfLines) {
    ellipsesStyle = {
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: numberOfLines,
    };
  }
  return ellipsesStyle;
};

export const scale = size => {
  return size;
};

export const getImageSize = (uri, callback) => {
  callback(50, 50);
};
